import React from 'react';
import { Link } from 'gatsby';

export default ({ color }) => {
    return (
        <div className={`${color || 'bg-gray-50'}`}>
            <div className="max-w-screen-xl mx-auto pt-12 pb-16 sm:pt-16 sm:pb-20 px-4 sm:px-6 lg:pt-28 lg:pb-28 lg:px-8">
                <h3 className="text-center mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
                    Questions fréquemment posées
                </h3>

                <p className="text-center mt-4 max-w-2xl text-xl leading-7 text-gray-500 lg:mx-auto">
                    Foire aux questions (FAQ)
                </p>

                <div className="mt-6 pt-10">
                    <dl className="md:grid md:grid-cols-2 md:gap-8">
                        <div>
                            <div>
                                <dt className="text-lg leading-6 font-medium text-gray-900">
                                    Ethibox, qu&apos;est ce que c&apos;est ?
                                </dt>
                                <dd className="mt-2">
                                    <p className="text-base leading-6 text-gray-500">
                                        Ethibox est un hébergeur web éthique membre du <a href="https://chatons.org" className="underline" target="_blank"><span className="font-bold">C</span>ollectif des <span className="font-bold">H</span>ébergeurs <span className="font-bold">A</span>lternatifs, <span className="font-bold">T</span>ransparents, <span className="font-bold">O</span>uverts, <span className="font-bold">N</span>eutres et <span className="font-bold">S</span>olidaires</a>.
                                    </p>
                                    <p className="text-base leading-6 text-gray-500 mt-2">Ethibox vous aide à gérer votre entreprise sans effort et de manière éthique.</p>
                                    <p className="text-base leading-6 text-gray-500 mt-2">Faites progresser votre entreprise, tout en protégeant votre vie privée et vos données.</p>
                                </dd>
                            </div>
                            <div className="mt-12">
                                <dt className="text-lg leading-6 font-medium text-gray-900">
                                    Comment annuler son abonnement ?
                                </dt>
                                <dd className="mt-2">
                                    <p className="text-base leading-6 text-gray-500">
                                        Vous pouvez annuler votre abonnement à tout moment via votre espace personnel. Nous vous donnons accès aux données de vos applications afin de les transférer chez un autre hébergeur si besoin. Toutes vos données sont automatiquement supprimées au bout de 30 jours.
                                    </p>
                                </dd>
                            </div>
                            <div className="mt-12">
                                <dt className="text-lg leading-6 font-medium text-gray-900">
                                    Où sont stockées les données ?
                                </dt>
                                <dd className="mt-2">
                                    <p className="text-base leading-6 text-gray-500">
                                        L'ensemble de l'infrastructure d'Ethibox est localisé en France (Reims) chez <a href="https://www.ikoula.com/fr/hebergement-web-eco-responsable" target="_blank">Ikoula</a>. Vos sauvegardes sont chiffrées en AES-256. Aucune de vos données personnelles n'est partagée à des tiers. Toutes les applications proposées sont open-source et sans tracking.
                                    </p>
                                </dd>
                            </div>
                            <div className="mt-12">
                                <dt className="text-lg leading-6 font-medium text-gray-900">
                                    Peut-on installer une application avec un nom de domaine personnalisé ?
                                </dt>
                                <dd className="mt-2">
                                    <p className="text-base leading-6 text-gray-500">
                                        Oui, il est possible d'installer toutes vos applications avec le nom de domaine de votre choix (exemple: <i>mondomaine.fr</i>) :

                                        <ul className="list-disc list-inside mt-2">
                                            <li><a href="https://ethibox.fr/app/fr/apps">Accédez à la liste de vos application</a></li>
                                            <li>Cliquez sur le bouton "Paramètres" de votre application</li>
                                            <li>Cliquez sur le bouton "Editer la configuration"</li>
                                            <li>Puis modifiez votre nom de domaine</li>
                                        </ul>
                                    </p>
                                </dd>
                            </div>
                        </div>
                        <div className="mt-12 md:mt-0">
                            <div>
                                <dt className="text-lg leading-6 font-medium text-gray-900">
                                    Les serveurs sont-ils alimentés en énergie renouvelable ?
                                </dt>
                                <dd className="mt-2">
                                    <p className="text-base leading-6 text-gray-500">
                                        Oui, les serveurs sont 100% alimentés par de l'énergie renouvelable (Éolien et hydraulique).
                                    </p>
                                </dd>
                            </div>
                            <div className="mt-12">
                                <dt className="text-lg leading-6 font-medium text-gray-900">
                                    Peut-on accéder aux applications via SSH ou FTP ?
                                </dt>
                                <dd className="mt-2">
                                    <p className="text-base leading-6 text-gray-500">
                                        Vous disposez des droits administrateur pour chaque application et une totale liberté sur leur utilisation. Pour des raisons de simplicité et de sécurité, nous ne donnons pas d'accès SSH ou FTP à nos serveurs.
                                    </p>
                                </dd>
                            </div>
                            <div className="mt-12">
                                <dt className="text-lg leading-6 font-medium text-gray-900">
                                    Y a-t-il une limite du nombre d'utilisateurs par application ?
                                </dt>
                                <dd className="mt-2">
                                    <p className="text-base leading-6 text-gray-500">
                                        Non, il n'y a pas de limites, vous pouvez avoir autant d'utilisateurs que vous le souhaitez, dans la limite du raisonnable.
                                    </p>
                                </dd>
                            </div>
                            <div className="mt-12">
                                <dt className="text-lg leading-6 font-medium text-gray-900">
                                    Peut-on installer des applications personnalisées ?
                                </dt>
                                <dd className="mt-2">
                                    <p className="text-base leading-6 text-gray-500">
                                        Non, il n'est pas possible d'héberger des applications personnalisées (NodeJs, PHP, Django, Nginx, Apache, MySQL, ect...) autres que celles proposées dans <Link to="/apps">le catalogue</Link>.
                                    </p>
                                </dd>
                            </div>
                            <div className="mt-12">
                                <dt className="text-lg leading-6 font-medium text-gray-900">
                                    Combien d'espace de stockage dispose-t-on ?
                                </dt>
                                <dd className="mt-2">
                                    <p className="text-base leading-6 text-gray-500">
                                        Chaque application dispose d'un espace de stockage de 50 Go par défaut et peut avoir jusqu'à 1000 Go (1 To).
                                    </p>
                                </dd>
                            </div>
                            <div className="mt-12">
                                <dt className="text-lg leading-6 font-medium text-gray-900">
                                    Quels sont les différents moyens de paiement ?
                                </dt>
                                <dd className="mt-2">
                                    <p className="text-base leading-6 text-gray-500">
                                        Vous pouvez payer par carte bancaire (Visa, MasterCard, American Express), prélèvement SEPA, virement bancaire, PayPal, ou par Chorus Pro (pour les administrations publiques).
                                    </p>
                                </dd>
                            </div>
                        </div>
                    </dl>
                </div>
            </div>
        </div>
    );
};
